<template>
    <div>
		<div v-if="!horse">
            <Spinner />
        </div>
		<div v-else>
			<div v-if="isAlreadySyndicated" class="box">
                <div class="row">
                    <div class="col-4">
                        <SeasonInput v-model="seasonSelected" />
                    </div>

                    <div class="col-4">
                        <e-select
                            v-model="syndic_selected"
                            id="syndic"
                            track-by="syndic_label"
                            label="syndic_label"
                            class="primary"
                            :options="all_syndic"
                            :searchable="true"
                            :show-labels="false"
                            :allow-empty="false"
                            sort-desc
                        >
                            <template slot="singleLabel" slot-scope="{ option }">{{ option.syndic_label }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                    </div>

                     <div class="col-2">
                        <b-button @click="editSyndicationForm">{{ $t('syndication.editer_syndication') }}</b-button>
                    </div>

                    <div class="col-2">
                        <b-button @click="createSyndicationForm">{{ $t('syndication.ajouter_syndication') }}</b-button>
                    </div>
                </div>


                <div v-if="loading">
                    <Spinner />
                </div>

                <div v-else-if="syndic_selected">
    				<CustomTable 
                        v-if="horse_syndic_details"
    					id_table="horse_syndic_details"
    					primaryKey="saillie_id"
    					:items="horseSaillies"
    					:busy="refreshing"
    					:columsAdd="['preview_history']"
    					:externSlotColumns="extern_slot_columns"
    				>
    					<template v-slot:custom-slot-cell(saillie_number)="{ data }">
    						#{{ data.saillie_number }}
    					</template>
    					<template v-slot:[`custom-slot-cell(saillie_part.syndicpart_tiers_formatted_table)`]="{ data }">
    						<span v-for="tierpart in data.saillie_part.syndicpart_tiers" :key="'tierpart_'+tierpart.tierpart_id">
    							<router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: tierpart.tierpart_tier.tiers_id }}"> {{ tierpart.tierpart_tier.tiers_rs }} ({{ tierpart.tierpart_percentage }}%)</router-link><br/>
    						</span>
    					</template>
    					<template v-slot:[`custom-slot-cell(saillie_part.syndicpart_is_perso)`]="{ data }">
    						<font-awesome-icon v-show="data.saillie_part.syndicpart_is_perso" :icon="['far', 'check']" />
    					</template>
    					<template v-slot:[`custom-slot-cell(saillie_part.syndicpart_is_pool)`]="{ data }">
    						<font-awesome-icon v-show="data.saillie_part.syndicpart_is_pool" :icon="['far', 'check']" />
    					</template>
    					<template v-slot:[`custom-slot-cell(saillie_part.syndicpart_invoice_type.invoicetype_labeltrad)`]="{ data }">
    						{{ $t(data.saillie_part.syndicpart_invoice_type.invoicetype_labeltrad) }}
    					</template>
    					<template v-slot:custom-slot-cell(preview_history)="{ data }">
    						<span class="d-flex align-items-center">
    							<font-awesome-icon class="previewColIdentifier" @click.stop="showHistory(data)" :icon="['fas', 'eye']" />
    						</span>
    					</template>
                        <template v-slot:custom-slot-cell(contract.contract_num)="{ data }">
                            <template v-if="data.contract">
                                <router-link :to="{ name: 'ContractFormv2', params: { contract_id: data.contract.contract_id }}">
                                    {{ data.contract.contract_num }}
                                </router-link>
                                <router-link :to="{ name: 'horseFiche', params: { horse_id: data.contract.contract_mare_id }}">
                                    {{ data.contract.contract_mare_nom ? " - " + data.contract.contract_mare_nom : null }}
                                </router-link>

                                <router-link v-if="data.contract.tiers" :to="{ name: 'tiersFiche', params: { tiers_id: data.contract.tiers.tiers_id }}">
                                    ({{ data.contract.tiers.tiers_rs }})
                                </router-link>
                            </template>
                        </template>
    					
    				</CustomTable>

                    <div v-else class="mt-4">
                        <b-alert show variant="warning">{{ $t('syndication.no_syndic_for_season') }}</b-alert>
                    </div>
                </div>
			</div>
			<b-button v-else @click="createSyndicationForm">{{ $t('syndication.activer_syndication') }}</b-button>
		</div>

        <CreateSyndicModal     ref="create_syndic_modal"      :submitForm="registerSyndication"   :horse_id="horse.horse_id" />
        <AddSyndicCarrierModal ref="add_syndic_carrier_modal" :submitForm="registerSyndicCarrier" :syndic="horse_syndic" :syndic_invoice_types="syndic_invoice_types" />
        <EditPartModal         ref="edit_part"                :submitForm="saveEditedPart"        :syndic_invoice_types="syndic_invoice_types" />
        <EditMultiPartModal    ref="edit_multi_part"          :submitForm="saveEditedMultiPart"   :syndic_invoice_types="syndic_invoice_types" />
        <AssignPartModal       ref="assign_part"              :submitForm="savePartCession"       :syndic_invoice_types="syndic_invoice_types" />

        <b-modal ref="modalLigneVierge" no-close-on-backdrop hide-footer>
            <template v-slot:modal-title>
                {{ $t("action.ajout_ligne_vierge") }}
            </template>
            <div v-if="!modal_loaded">
                <LoadingSpinner class="col-12" />
            </div>
            <div v-if="modal_loaded">
                <LigneVierge ref="ligne" :horse="horse" :saillies="parts_invoice" :ready.sync="ready" :processing.sync="processing"></LigneVierge>
                <div class="col-8 m-auto">
                    <b-button v-if="ready" block pill variant="primary" @click="onSubmitInvoice"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
    import Navigation from "@/mixins/Navigation.js"
    import Shutter from "@/mixins/Shutter.js"
    import Syndic from "@/mixins/Syndic.js"
	import ShutterSyndic from "@/mixins/shutters-manager/Syndic.js"

	export default {
        name: "ActualSyndicShutter",
        mixins: [Navigation, Shutter, Syndic, ShutterSyndic],
        props: {
            horse: { type: Object, default: null }
        },
        data() {
            return {
                horse_syndic: null,
                horse_syndic_details: null,
				syndic_invoice_types: [],
                loading: false,
                refreshing: false,
                extern_slot_columns: [
                    'saillie_number',
                    'saillie_part.syndicpart_is_perso',
                    'saillie_part.syndicpart_is_pool',
                    'saillie_part.syndicpart_invoice_type.invoicetype_labeltrad',
                    'saillie_part.syndicpart_tiers_formatted_table',
					'preview_history',
                    'contract.contract_num'
				],
				// config_table_hrefs: {
                //     'contract.contract_num': {
                //         routeUniqueName: 'ContractFormv2',
                //         routeName: 'ContractFormv2',
                //         params: {
                //             contract_id: 'contract.contract_id'
                //         }
                //     },
                // },
                events_tab: {
                    'TableAction::goToAddSyndicParts': this.addSyndicCarrierForm,
                    'TableAction::goToEditPart': this.editPartForm,
                    'TableAction::goToEditMultiPart': this.editMultiPartForm,
                    'TableAction::gotToAssignParts': this.assignSyndicPartsForm,
                    'TableAction::goToInvoicePart': this.openLigneViergeModal
                },
				selected_lines: [],
                seasonSelected: null,
                syndic_selected: null,
                all_syndic: [],
                parts_invoice: [],
                processing: false,
                ready: false,
                modal_loaded: false,
                options:  {type: "Syndic"}
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
				this.all_syndic = await this.loadHorseAllSyndic(this.horse.horse_id)

                if(this.all_syndic.length == 1) {
                    this.syndic_selected = this.all_syndic[0]
                }

                this.loadSyndicInvoiceTypes().then(res => this.syndic_invoice_types = res)
            },

            async loadHorseSyndic() {
                if(!this.seasonSelected.id || !this.syndic_selected) {
                    return false
                }

                this.loading = true
                this.horse_syndic = await this.loadSyndic(this.syndic_selected.syndic_id)

                await this.loadSyndicDetails()

                this.loading = false
            },

            async loadSyndicDetails() {
				this.refreshing = true
                if(this.horse_syndic && this.horse_syndic.syndic_id) {
                    this.horse_syndic_details = await this.loadHorseSyndicDetails(this.horse.horse_id, this.horse_syndic.syndic_id, this.seasonSelected.id)
                }
                this.refreshing = false
            },

            /* Create a new syndication, in vue template */
            createSyndicationForm() {
                this.$refs.create_syndic_modal.openModal()
			},
			
            /* Edit an existing syndication with create modal, in vue template */
			editSyndicationForm() {
                if(!this.horse_syndic) {
                    return false
                }
                this.$refs.create_syndic_modal.openModal(this.horse_syndic.syndic_id)
			},

            /* Save syndication parameters */
            registerSyndication(params) {
				let promise = null

                if(this.horse_syndic) {
                    promise = this.editHorseSyndic(this.horse_syndic.syndic_id, params)
                }
                else {
                    promise = this.registerHorseSyndic(this.horse.horse_id, params)
                }

				promise
					.then(res => {
						this.horse_syndic = res.retour
						this.successToast('syndication.toast_syndic_enregistre')
					})
					.catch(e => {
						console.error("StallionSyndication::registerSyndication => Error:", e)
					})
					.finally(() => {
						this.$refs.create_syndic_modal.closeModal()
                        this.init_component()
					})
            },

            /* Add carrier on a horse */
            addSyndicCarrierForm() {
                this.$refs.add_syndic_carrier_modal.openModal()
            },

            /* Save the syndicate carriers */
            registerSyndicCarrier(params) {
                this.registerHorseCarriersParts(this.horse.horse_id, this.horse_syndic.syndic_id, this.seasonSelected.id, params)
                    .then(res => {
                        this.$refs.add_syndic_carrier_modal.closeModal()
                        this.successToast('syndication.toast_parts_enregistrees')
                    })
                    .catch(e => {
                        console.error("StallionSyndication::registerSyndicCarrier => Error:", e)
                    })
                    .finally(() => {
                        this.init_component()
                        this.loadSyndicDetails()
                    })
            },

            /* Edit a part */
            editPartForm(saillies) {
                const saillie = saillies[0]
                this.$refs.edit_part.openModal(saillie.saillie_part)
            },

            editMultiPartForm(saillies) {
                this.$refs.edit_multi_part.openModal(saillies)
            },

            saveEditedPart(params) {
                this.editHorseSyndicParts(this.horse_syndic.syndic_id, params.syndicpart_id, params)
                    .then(res => {
                        this.$refs.edit_part.closeModal()
                        this.successToast('syndication.toast_parts_update')
                    })
                    .catch(e => {
                        console.error("StallionSyndication::registerSyndication => Error:", e)
                    })
                    .finally(() => {
                        this.loadSyndicDetails()
                    })
            },

            saveEditedMultiPart(params) {
                this.editHorseSyndicMultiParts(this.horse_syndic.syndic_id, params.syndicpart_ids, params)
                    .then(res => {
                        this.$refs.edit_multi_part.closeModal()
                        this.successToast('syndication.toast_parts_update')
                    })
                    .catch(e => {
                        console.error("StallionSyndication::registerSyndication => Error:", e)
                    })
                    .finally(() => {
                        this.loadSyndicDetails()
                    })
            },

            /* Assign a part - cession */
            assignSyndicPartsForm(saillies) {
                const saillie = saillies[0]
                this.$refs.assign_part.openModal(saillie.saillie_part)
            },

            savePartCession(params) {
                this.saveHorseCessionSyndicPart(this.horse_syndic.syndic_id, params.syndicpart_id, params)
                    .then(res => {
                        this.$refs.assign_part.closeModal()
                        this.successToast('syndication.toast_parts_assignees')
                    })
                    .catch(e => {
                        console.error("StallionSyndication::registerSyndication => Error:", e)
                    })
                    .finally(() => {
                        this.loadSyndicDetails()
                    })
            },

			showHistory(line) {
                this.setupHistorySyndic(line.saillie_part.syndicpart_id)
			},

            openLigneViergeModal(saillies) {
                this.modal_loaded = false

                this.parts_invoice = saillies
                this.$refs.modalLigneVierge.show()

                this.modal_loaded = true
            },

            async onSubmitInvoice() {
                this.processing = true
                let invoice_details = await this.$refs.ligne.checkForm()
                if(invoice_details)
                {
                    this.$refs.modalLigneVierge.hide()
                    this.successToast()
                }
                this.processing = false
                this.ready = true
            }
        },
        computed: {
            isAlreadySyndicated() {
                return this.all_syndic !== null
			},
			hasAlreadySomeParts() {
				return this.horseSaillies.length > 0	
			},
			canEditSyndic() {
				return this.isAlreadySyndicated && !this.hasAlreadySomeParts
			},
            horseSaillies() {
                if(!this.horse_syndic_details) return []
                return this.horse_syndic_details.syndic_saillies
            },
        },
        watch: {
			canEditSyndic(val) {
				if(this.loading === true) return 
				EventBus.$emit('ActualSyndicShutter::updateEditionPermission', val)
			},
			loading(val) {
				if(val === true) return
				EventBus.$emit('ActualSyndicShutter::updateEditionPermission', this.canEditSyndic)
			},
            seasonSelected() {
                this.loadHorseSyndic()
            },
            syndic_selected() {
                this.loadHorseSyndic()
            }
        },
        components: {
            AssignPartModal: () => import("@/components/Syndication/Modals/AssignPart"),
            AddSyndicCarrierModal: () => import("@/components/Syndication/Modals/AddCarrier"),
            CreateSyndicModal: () => import("@/components/Syndication/Modals/CreateSyndic"),
            CustomTable: () => import("GroomyRoot/components/Table/CustomTable"),
            EditPartModal: () => import("@/components/Syndication/Modals/EditPart"),
            EditMultiPartModal: () => import("@/components/Syndication/Modals/EditMultiPartModal"),
            Spinner: () => import("GroomyRoot/components/Logos/LoadingSpinner_35"),
            SeasonInput : () => import('@/components/Inputs/SeasonInput'),
            LigneVierge: () => import('@/components/Syndication/Modals/LigneViergeSyndic')
        }
    }
</script>